<template>
  <div>
    <div
      class="content"
      v-if="$route.query.type != 2"
      :style="
        $route.query.type == 1
          ? 'background:url(https://ppyos.xijiuyou.com/imgs/invite/cy-inv-bg.png) no-repeat;background-size:100% 100%;'
          : ''
      "
    >
      <img
        src="https://ppyos.xijiuyou.com/imgs/invite/ppywicon.png"
        alt=""
      />
      <img
        v-if="$route.query.type != 1"
        src="https://ppyos.xijiuyou.com/imgs/invite/ppywenmonytog.png"
        alt=""
      />
      <img
        v-if="$route.query.type != 1"
        src="https://ppyos.xijiuyou.com/imgs/invite/midbanner.png"
        alt=""
      />
      <img
        v-if="btn"
        class="content-btn tag-reads"
        :data-clipboard-text="code"
        @click="copys"
        :class="$route.query.type == 1 ? 'trans' : ''"
        :src="
          $route.query.type == 1
            ? 'https://ppyos.xijiuyou.com/imgs/invite/cy-inv-download.png'
            : 'https://ppyos.xijiuyou.com/imgs/invite/downbtn.gif'
        "
        alt=""
      />
      <div class="content-ioss" v-else-if="!btn && $route.query.type == 1">
        iOS敬请期待
      </div>
      <div v-else class="content-ios">iOS敬请期待</div>
      <div class="weixin" v-if="wchat">
        <img
          src="https://ppyos.xijiuyou.com/imgs/invite/brower_pointer.png"
          alt=""
        />
        <img
          src="https://ppyos.xijiuyou.com/imgs/invite/open_browser.png"
          alt=""
        />
      </div>
    </div>
    <div class="applingq tag-reada" :data-clipboard-text="code"  @click="applingq" v-else>
      <img
        v-if="btn"
        src="https://ppyos.xijiuyou.com/202201/rdskin-downapp-banner.jpg"
        alt=""
      />
      <div v-else class="content-ios">iOS敬请期待</div>
      <div class="weixin" v-if="wchat">
        <img
          src="https://ppyos.xijiuyou.com/imgs/invite/brower_pointer.png"
          alt=""
        />
        <img
          src="https://ppyos.xijiuyou.com/imgs/invite/open_browser.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import Clipboard from "clipboard";
import { Toast } from "vant";
export default {
  data() {
    return {
      btn: true,
      code: "",
      wchat: false,
    };
  },
  mounted() {
    var u = navigator.userAgent;
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isiOS) {
      this.btn = false;
    }
    this.code = "@XW" + this.$route.query.code + "WX@";
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.wchat = true;
    } else {
      this.wchat = false;
    }
  },
  methods: {
    copys() {
      var clipboard = new Clipboard(".tag-reads");
      clipboard.on("success", () => {
        Toast("复制成功");
        if (this.$route.query.type == 1) {
          window.open(
            "https://ppyos.xijiuyou.com/apks/version/lasted/sgame-ppyw_aligned_signed.apk"
          );
        } else {
          window.open(
            "https://ppyos.xijiuyou.com/apks/version/lasted/1111-ppyw_aligned_signed.apk"
          );
        }
      });
    },
    applingq() {
      
      var clipboard = new Clipboard(".tag-reada");
      clipboard.on("success", () => {
        console.log(1)
        Toast("复制成功");
        if (this.btn) {
          window.open(
            "https://ppyos.xijiuyou.com/apks/version/lasted/rpskn-ppyw_aligned_signed.apk"
          );
        }
      });
    },
  },
};
</script>
<style scoped>
.weixin {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.72);
  text-align: right;
}
.weixin img:nth-child(1) {
  position: absolute;
  width: 60px !important;
  right: 40px !important;
  display: block;
  left: auto !important;
  bottom: auto !important;
  top: 30px !important;
}
.weixin img:nth-child(2) {
  position: absolute;
  width: 180px !important;
  right: 20px !important;
  top: 100px !important;
  display: block;
  left: auto !important;
  bottom: auto !important;
  margin: 0 !important;
}
.content {
  width: 100vw;
  height: 100vh;
  background: url("https://ppyos.xijiuyou.com/imgs/invite/invbg.png")
    no-repeat;
  background-size: 100% 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.content img:nth-child(1) {
  width: 129px;
}
.content img:nth-child(2) {
  width: calc(100% - 20px);
  margin-top: 36px;
}
.content img:nth-child(3) {
  width: 240px;
  margin-top: 10px;
}
.content-btn {
  position: absolute;
  width: 275px !important;
  bottom: 20px !important;
  left: calc(50% - 137px) !important;
  margin: 0 !important;
}
.content-ios {
  position: absolute;
  width: 244px;
  height: 48px;
  background: #d8d8d8;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  left: calc(50% - 122px);
  bottom: 30px;
}
.content-ioss {
  position: absolute;
  width: 244px;
  height: 48px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  left: calc(50% - 122px);
  bottom: 30px;
}
.trans {
  width: 297px !important;
  height: 65px !important;
  animation: scaleDraw 5s ease-in-out infinite;
  left: calc(50% - 148px) !important;
}
@keyframes scaleDraw {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
    transform: scale(1); /*开始为原始大小*/
  }
  10% {
    transform: scale(0.9); /*放大1.1倍*/
  }
  25% {
    transform: scale(1); /*放大1.1倍*/
  }
  35% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.9);
  }
  75% {
    transform: scale(1);
  }
  85% {
    transform: scale(0.9);
  }
}
.applingq {
  width: 100vw;
  height: 100vh;
  position: relative;
  justify-content: center;
  background: url("https://ppyos.xijiuyou.com/202201/rdskin-landing-bg.jpg")
    no-repeat;
  background-size: 100%;
}
.applingq img {
  position: absolute;
  bottom: 22px;
  width: 270px;
  left: calc(50% - 135px);
}
</style>